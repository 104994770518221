import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import CenteredContainer from '../../../common/CenteredContainer';
import LocalizedPizzaMenu from '../../../menu/pizza/LocalizedPizzaMenu';
import PizzaBuilderContainer from '../../pizza/PizzaBuilderContainer';
import {
  updateDealStep,
  userSelectionsSelector
} from '../slice/userSelections.slice';
import { CurrentStep, DealRecipe } from '../slice/dealTypes';
import StepType from '../StepType';
import ProductMenuStep from './ProductMenuStep/ProductMenuStep';
import DealType from '@/builders/deals/DealTypes';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { RootState } from '@/rootStateTypes';
import { NATIONAL } from '@/localization/constants';
import usePizzaDataInRedux from '@/builders/deals/hooks/usePizzaDataInRedux';
import useDealExitFunction from '@/builders/deals/hooks/useDealExitFunction';
import AddToDealButton from '@/builders/deals/AddToDeal';
import AddDealToCart from '@/builders/deals/AddToDeal/AddDealToCart';
import { onPizzaMenuTileClickInsideDeal } from '../analytics/analytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import useDealRecipesPriceHardcoding from '../../../../hardcoding/utils/useDealRecipesPriceHardcoding';
import { PIZZA } from '@/domain/constants';

interface Props {
  isEditFlow?: boolean;
}

const DealPizzaBuilder = ({ isEditFlow = false }: Props): JSX.Element => {
  const recipes = useSelector(
    userSelectionsSelector.selectRecipesForCurrentStep
  );
  const dataLoading = usePizzaDataInRedux();
  const dealType = useSelector(dealSelectors.selectDealType);
  const isPizza = recipes?.[0]?.type === PIZZA;
  const isLocalized = useSelector(
    (state: RootState) => state.domain.localization.localizedStore !== NATIONAL
  );
  const [{
    enabled: isSingleStepPizzaDealQuantityPickerEnabled, variationKey
  }] = useDecision('fr-dtg-218-quantity-picker-single-step-pizza-deal');

  const isSingleStepDealQtyPickerABTestOn = isSingleStepPizzaDealQuantityPickerEnabled && variationKey === 'control_qty_pick_pizza_deal_on';

  return (
    <PizzaBuilderContainer
      recipes={recipes}
      isLocalized={isLocalized}
      loading={dataLoading}
      onExit={useDealExitFunction()}
      AddToCartButton={
        dealType === DealType.MULTI_STEP ? (
          <AddToDealButton />
        ) : (
          <AddDealToCart
            isEditFlow={isEditFlow}
            showQuantity={isSingleStepDealQtyPickerABTestOn && isPizza}
          />
        )
      }
    />
  );
};

const SingleRecipe = (props: Props): JSX.Element => (
  <DealPizzaBuilder {...props} />
);

const MultiRecipes = (props: Props): JSX.Element => {
  const recipes = useSelector(
    userSelectionsSelector.selectRecipesForCurrentStep
  );
  const dispatch = useDispatch();
  const step = useSelector(userSelectionsSelector.selectCurrentStep);
  const analytics = useAnalytics();
  const { deal_id: dealId, deal_name: dealName } = analytics.analyticsDataModel.deal;

  const recipesWithUpdatedPrices = useDealRecipesPriceHardcoding(recipes);

  if (!recipesWithUpdatedPrices) return <></>;

  if (step?.recipeId) return <DealPizzaBuilder {...props} />;

  const handlePizzaClick = (recipe: DealRecipe) => {
    const currentStep: Partial<CurrentStep> = {
      ...step,
      recipeId: recipe.id,
      recipeType: recipe.type
    };
    dispatch(updateDealStep(currentStep));

    if (dealId && dealName) {
      const selectedPizzaIndex = recipes?.indexOf(recipe);
      analytics.push(() => onPizzaMenuTileClickInsideDeal(
        dealName,
        dealId,
        recipe,
        selectedPizzaIndex ?? 0
      ));
    }
  };

  return (
    <>
      <CenteredContainer>
        <LocalizedPizzaMenu
          heading="Pizza"
          recipes={recipesWithUpdatedPrices}
          onPizzaClick={handlePizzaClick}
        />
      </CenteredContainer>
    </>
  );
};

const DealStep = ({
  step,
  isEditFlow = false
}: {
  step?: CurrentStep | null;
  isEditFlow?: boolean;
}): JSX.Element => {
  switch (step?.type) {
    case StepType.SINGLE_PIZZA_RECIPE: {
      return <SingleRecipe isEditFlow={isEditFlow} />;
    }
    case StepType.MULTI_PIZZA_RECIPE: {
      return <MultiRecipes isEditFlow={isEditFlow} />;
    }
    case StepType.MENU_RECIPE: {
      return (
        <ProductMenuStep
          stepName={step.name}
          recipes={step.recipeOptions}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

export default DealStep;
